import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

import { BlankSection as staticBlankSection } from '../src/components/layouts/sections';
import { Technology as staticTechnology } from '../src/components/OurTechnology';
import axios from 'axios';

import { GetServerSideProps } from 'next';
import { getBaseUrl } from '../src/util/serverUtils';

/**
 * Replaced static loading with
 * next dynamic loading
 */
// import Testimonial from '../src/components/testimonial';
// import SimpleCenterCTA from '../src/components/herosection/simpleCenterCTA';
// import HeaderFooter from '../src/components/layouts/headerfooter';
// import HeroSection from '../src/components/herosection';
// import MetaDecorator from '../src/core/meta/MetaDecorator';
// import QuoteSection from '../src/components/quotesection';
// import WeSection from '../src/components/wesection';
// import WorkSection from '../src/components/worksection';
// import ServiceSection from '../src/components/services';

const HeaderFooter = dynamic(
    () => import('../src/components/layouts/headerfooter')
);
const HeroSection = dynamic(() => import('../src/components/herosection'));
const MetaDecorator = dynamic(() => import('../src/core/meta/MetaDecorator'));
const QuoteSection = dynamic(() => import('../src/components/quotesection'));
const WeSection = dynamic(() => import('../src/components/wesection'));
const WorkSection = dynamic(() => import('../src/components/worksection'));
const ServiceSection = dynamic(() => import('../src/components/services'));
const Testimonial = dynamic(() => import('../src/components/testimonial'));
const SimpleCenterCTA = dynamic(
    () => import('../src/components/herosection/simpleCenterCTA')
);
const Technology = dynamic<React.ComponentProps<typeof staticTechnology>>(() =>
    import('../src/components/OurTechnology').then((mod) => mod.Technology)
);
const FeaturedArticles = dynamic<any>(
    () => import('../src/components/featuredArticles')
);
const BlankSection = dynamic<React.ComponentProps<typeof staticBlankSection>>(
    () =>
        import('../src/components/layouts/sections').then(
            (mod) => mod.BlankSection
        )
);
const SectionWithHeadingDesc = dynamic<any>(() =>
    import('../src/components/layouts/sections').then(
        (mod) => mod.SectionWithHeadingDesc
    )
);
const BlankSectionNoSpacing = dynamic<any>(() =>
    import('../src/components/layouts/sections').then(
        (mod) => mod.BlankSectionNoSpacing
    )
);

export default function Home({ data, canonicalUrl }: { data: any, canonicalUrl:string }) {
    return (
        <>
            <HeaderFooter>
                <MetaDecorator
                    title={
                        data?.attributes?.seo
                            ? data?.attributes?.seo?.metaTitle
                            : 'Virtual gravity | Home'
                    }
                    description={
                        data?.attributes?.seo
                            ? data?.attributes?.seo?.metaDescription
                            : 'Virtual Gravity is an IT company based in Melbourne, Australia, with a development team in Nepal. We offer high-quality and cost-effective web and mobile application development services to clients worldwide. Contact us today to learn how we can help your business succeed through technology.'
                    }
                    imageUrl={
                        data?.attributes?.seo
                            ? data?.attributes?.seo?.metaTitle
                            : 'https://virtual-gravity.com/images/logo.svg'
                    }
                    imageAlt={
                        data?.attributes?.seo
                            ? data?.attributes?.seo?.media?.alt
                            : 'virtual-gravity-image'
                    }
                    keywords={
                        data?.attributes?.seo
                            ? data?.attributes?.seo?.keywords
                            : 'virtual-gravity'
                    }
                    canonicalUrl={canonicalUrl}
                />
                <BlankSection ngClass={'bg-no-repeat '}>
                    <HeroSection />
                </BlankSection>

                <BlankSection ngClass={'relative z-10 lg:min-h-[400px]'}>
                    <WorkSection />
                </BlankSection>

                <BlankSection ngClass={'bg-light-gradient lg:-mt-[11.5rem]'}>
                    <WeSection />
                </BlankSection>

                <SectionWithHeadingDesc
                    ngClass={'bg-white '}
                    topHeading={'Our'}
                    heading={'Services'}
                    description={`We are a design and development company providing services of eCommerce, Mobile Application Development,  UI/UX Design, Web 3.0, NFT &  Blockchain Technology and Web Development `}
                >
                    <ServiceSection />
                </SectionWithHeadingDesc>

                <BlankSection ngClass="bg-dark-gradient relative">
                    <Testimonial />
                </BlankSection>

                <BlankSection ngClass={'bg-white'}>
                    <Technology />
                </BlankSection>

                <BlankSectionNoSpacing
                    ngClass={'relative bg-dark-gradient lg:-mt-12'}
                >
                    <QuoteSection />
                </BlankSectionNoSpacing>

                <SectionWithHeadingDesc
                    ngClass={'bg-white '}
                    topHeading={'Featured'}
                    heading={'articles'}
                    description=""
                >
                    <FeaturedArticles />
                </SectionWithHeadingDesc>

                <BlankSection ngClass={'bg-white'}>
                    <SimpleCenterCTA
                        topHeading={'Let’s grow your'}
                        heading={'Business with tech'}
                        description={`Do you want to have a mobile that stands out and impresses your clients?`}
                    />
                </BlankSection>
            </HeaderFooter>
        </>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    //@ts-ignore
    const currentPath = getBaseUrl(context.req) + context.req.url;

    try {
        const result = await axios.get(
            `${process.env.admin_url}/api/home?populate=*`
        );
        const data = result?.data?.data;

        return {
            props: {
                data,
                canonicalUrl: currentPath.includes('.json') ? null : currentPath,
            },
        };
    } catch (error) {
        console.error('Error fetching data:', error);
        return {
            props: {
                data: null,
                canonicalUrl: currentPath.includes('.json') ? null : currentPath,
            },
        };
    }
}
